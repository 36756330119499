import React from "react";
import { Link } from "gatsby";

import Scaffold from "../../../components/portfolio";
import mesite from "../../../images/mesite.png";

const Design = (props) => (
  <Scaffold pathname={props.location.pathname}>
    <h2>Website Design</h2>

    <h3>Portfolio Site</h3>

    <div
      style={{
        display: "flex",
        justifyContent: "space-around",
        paddingTop: 20,
        paddingBottom: 20,
      }}
    >
      <div>
        <Link to="#">
          <div className="browser-mockup">
            <img style={{ maxWidth: "100%", height: 180 }} src={mesite} />
          </div>
        </Link>
      </div>
    </div>

    <p>
      This is the website you are currently looking at now. I built it using
      GatsbyJS, a static website builder which uses React and GraphQL. The code
      is built into a set of static, cacheable files which are uploaded to S3
      behind a Cloudfront distribution. This ensures a fast browsing experience
      for users.
    </p>

    <p>
      Through a combination of various GatsbyJS plugins, the Code Blog is built
      up from a collection of markdown files, automatically scaling and
      optimising images, syntax highlighting code snippets and building indexes.
      The process for deploying a new post is incredibly easy, and involves
      simply creating a new file and deploying the changes to S3.
    </p>
  </Scaffold>
);

export default Design;
